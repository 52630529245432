export const environment = {
  production: true,
  env: 'admin',
  apiUrls: {
    // api: 'https://admin.strategystreet.one',
    // api: 'https://strategy.codingcopz.in',
    api: 'https://api.strategystreet.one/v1',
  },
  firebase: {
    apiKey: 'AIzaSyCEIxiOdZmI2DZTNpE6y3PGifc519rUM_k',
    authDomain: 'strategystreet-d5e9e.firebaseapp.com',
    databaseURL: 'https://strategystreet-d5e9e-default-rtdb.firebaseio.com',
    projectId: 'strategystreet-d5e9e',
    storageBucket: 'strategystreet-d5e9e.appspot.com',
    messagingSenderId: '100098975722',
    appId: '1:100098975722:web:4d277def87580cff983493',
    measurementId: 'G-PRLKTHZV4L',
  },
  s3BaseUrl: 'https://strategy-street.s3.amazonaws.com/strategyStreet'
};
