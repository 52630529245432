
<div class="py-3">
<div class="text-center">
  <div>
    {{data.text}}
  </div>
</div>
<div class="justify-center mt-4">
  <button (click)="dialogRef.close(true)" class="theme-btn blue mr-3 cursor">Yes</button>
  <button (click)="dialogRef.close(false)" class="theme-btn black cursor" >No</button>
</div>
</div>
