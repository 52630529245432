import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { AppService } from "../services/app.service";

@Injectable({
    providedIn: 'root'
})

export class LoginGuard implements CanActivate {

    constructor(private router: Router, private appService: AppService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!localStorage.getItem('token')) {
            return true
        }
        if (environment.env == 'admin') {
            this.router.navigate(['/admin'])
        } else {
            this.router.navigate(['/cmpAdmin'])
        }
        return false;
    }

}