import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

// admin
const admin: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/main-modules/main.module').then((m) => m.MainModuleModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'public',
    loadChildren: () => import('./public/reset.module').then((m) => m.ResetModule),
  },
  { path: '**', redirectTo: '' }
];

// company
const company = [
  { path: '', redirectTo: 'cmpAuth', pathMatch: 'full' },
  {
    path: 'cmpAuth',
    loadChildren: () => import('./company-module/company-auth/company-auth.module').then((m) => m.CompanyAuthModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'cmpAdmin',
    loadChildren: () => import('./company-module/company-modules/company-main.module').then((m) => m.CompanyMainModule),
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: 'cmpAuth' }
]

let routes: Routes = admin;
routes = environment.env.includes('company') ? company : admin;
@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
