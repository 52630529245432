import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { User } from '../model/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from '../sharemodule/confirmation-popup/confirmation-popup.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AppService {

  constructor(
    public _snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog
  ) { }

  toster(message: string, duration?: number) {
    this._snackBar.open(message, 'Close', {
      duration: duration || 2000,
    });
  }

  confirmation(text: string): Observable<boolean> {
    return this.dialog.open(ConfirmationPopupComponent, {
      panelClass: 'prf-modal',
      minWidth: '370px',
      data: { text: text },
    }).afterClosed()
  }

  valueToBeUpdate(newObject: any, oldObject: any) {
    let updatedObject: any = {};
    Object.keys(newObject).forEach((k) => {
      if (oldObject[k]) {
        if (typeof newObject[k] == 'object') {
          if (JSON.stringify(newObject[k]) !== JSON.stringify(oldObject[k])) {
            updatedObject[k] = newObject[k]
          }
        } else {
          if (newObject[k] !== oldObject[k]) {
            updatedObject[k] = newObject[k]
          }
        }
      } else {
        updatedObject[k] = newObject[k]
      }
    });
    return updatedObject
  }

  isObjectEmpty(object: any) {
    for (let obj in object) {
      if (object[obj]) {
        return false
      }
    }
    return true
  }

  formGroupSetValue(form: FormGroup, value: { [key: string]: any }) {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      if (value[key] !== undefined) {
        if (control instanceof FormControl) {
          control.setValue(value[key]);
        } else if (control instanceof FormGroup) {
          this.formGroupSetValue(control, value[key])
        } else if (control instanceof FormArray) {
          control.controls.forEach((arrayControl, index) => {
            const arrayItem: Array<any> = value[key][index];
            if (arrayControl instanceof FormControl) {
              arrayControl.setValue(arrayItem);
            } else if (arrayControl instanceof FormGroup) {
              this.formGroupSetValue(arrayControl, arrayItem);
            }
          })
        }
      }
    })
  }

  get token() {
    return localStorage.getItem('token') || '';
  }

  set token(token: string) {
    localStorage.setItem('token', token);
  }

  get user() {
    if (localStorage?.getItem('user')) {
      return JSON.parse(localStorage?.getItem('user') || '');
    } else {
      return new User();
    }
  }

  set user(u: User) {
    localStorage.setItem('user', JSON.stringify(u));
  }

  clearAdmin() {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

}
