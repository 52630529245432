import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { IApi } from 'src/app/http-handler/common/contracts/api';
import { GenericApi } from 'src/app/http-handler/common/generic-api';
import { User } from '../model/user.model';
import { ConfirmationPopupComponent } from '../sharemodule/confirmation-popup/confirmation-popup.component';
import { AppService } from './app.service';


@Injectable({
  providedIn: 'root'
})

export class UserService {

  userid?: any;
  adminTeam: IApi<User>;
  users: IApi<User>;

  constructor(
    public http: HttpClient,
    private appservice: AppService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.users = new GenericApi<User>('users', http)
    this.adminTeam = new GenericApi<User>('users/create/team', http)
    setInterval(() => {
      if ((appservice?.user?.id && appservice?.token) && appservice.user.role !== 'admin' && !localStorage?.getItem('noCheckToken')) {
        this.updateLocalUser();
      }
    }, 10000)
  }

  signIn(body: any): Promise<User> {
    return this.users.create({ ...body }, 'signin').then((d: any) => {
      if (d.role == 'adminTeam' || d.role == 'admin') {
        this.appservice.token = d.token;
        this.appservice.user = d;
        this.appservice.toster('You have successfully logged In.');
        return d
      }
      else {
        this.appservice.toster('Only admin and admin team can login.');
        return;
      }
    }).catch(e => {
      this.appservice.toster(e);
      return;
    })
  }

  companySignIn(body: any): Promise<User> {
    return this.users.create({ ...body }, 'signin').then((d: any) => {
      if (d.role == 'companyTeam' || d.role == 'company') {
        this.appservice.token = d.token;
        this.appservice.user = d;
        this.appservice.toster('You have successfully logged In.');
        return d
      }
      else {
        this.appservice.toster('Only company and company team can login.');
        return;
      }
    }).catch(e => {
      this.appservice.toster(e);
      return;
    })
  }

  run: boolean;
  updateLocalUser() {
    this.userid = this.appservice.user.id;
    if (this.run) {
      return
    }
    this.run = true;
    return this.users.get(this.userid).then((d) => {
      this.run = false;
      // d.company.paymentStatus='pending';
      // d.company.approvalStatus = 'expired';
      // d.company.subscriptionExpiryDate = '2021-08-16'
      if (d?.company?.subscriptionExpiryDate) d.company.expiryDays = moment(d?.company?.subscriptionExpiryDate).diff(new Date(), 'days');
      if (d?.company?.expiryDays && d?.company?.expiryDays < 0) {
        d.company.expiryDays -= d.company.expiryDays *= 2
      };
      if (d?.role == 'company' && (d?.company?.approvalStatus == 'cancelled' || d?.company?.approvalStatus == 'expired')) {
        this.router.navigate(['/cmpAdmin/account'])
      }
      if (d?.role?.includes('companyMember') && (d?.company?.status == 'inactive' || d?.company?.approvalStatus == 'cancelled' || d?.company?.approvalStatus == 'expired')) {
        localStorage.clear();
        this.router.navigate(['/'])
      }

      return this.appservice.user = d;
    }).catch(e => {
      this.run = false;
      this.appservice.toster(e);
      return e;
    });
  }

  delete(id: number): Promise<string> {
    return this.users.remove(id).then(d => {
      this.appservice.toster('User deleted successfully.');
      return d;
    }).catch(err => {
      this.appservice.toster(err);
      return err
    })
  }






  confirmation(text: string, fun: Function) {
    this.dialog.open(ConfirmationPopupComponent, {
      panelClass: 'prf-modal',
      maxWidth: '370px',
      data: { text: text },
    }).afterClosed().subscribe(result => {
      if (result) {
        fun();
      }
    })
  }

}

