import { GroupService } from "../services/group.service";
import { Company } from "./company.model";
import { Group } from "./group.model";

export class User {
    id?: number;
    firstName?: string;
    lastName?: string;
    token?: string;
    email?: string;
    password?: string;
    name?: string;
    city?: string;
    country?: string;
    brief?: string;
    inviteCount?: number;
    twitterId?: string;
    linkedInId?: string;
    role?: string;
    status?: string;
    isEmailVerified?: string;
    activeGroups?: Group[];
    subGroupChairNames?: [];
    loginType?: string;
    jobTitle?: string;
    department?: string;
    imgUrl?: string;
    createdAt?: string;
    isAvailable?: boolean;
    company?: Company;
    userCard?: UserCard;
    days?:number;
}

export class UserCard {
    name?: string
    id?: number
    cardNumber?: string
    expMonth?: string
    expiryDate?: string
    expYear?: string
}